import * as React from 'react'
import PropTypes from 'prop-types'
import { render } from 'storyblok-rich-text-react-renderer'
import { debounce } from '@material-ui/core/utils'
import makeStyles from '@material-ui/core/styles/makeStyles'
import InView from '@oakwood/oui/InView'
import { useRouter } from 'next/router'
import clsx from 'clsx'
import Cookies from 'js-cookie'
import { fade } from '@material-ui/core/styles/colorManipulator'
import CloseIcon from 'components/icons/Close'
import IconButton from 'components/IconButton'
import Section from 'components/Section'
import { useTranslations } from 'containers/Translations/TranslationsContext'
import Typography from 'components/Typography'
import getApiClient from 'utils/apiClient'
import { useGlobal } from 'api'
import getConfig from 'utils/getConfig'
import MarqueeItem from 'containers/MarqueeItem'

const { publicRuntimeConfig } = getConfig() || {}

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    marginTop: -1,
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  bounds: theme.mixins.absolute(0),
  wrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    height: '100%',
  },
  text: {
    minWidth: '100vw',
    paddingLeft: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
  closeButton: {
    position: 'absolute',
    right: 16,
    top: -21,
    padding: 2,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 16,
  },
  noAnimateText: {
    '& > p > a': {
      color: '#000',
    },
  },
  referralCardContainer: {
    backgroundColor: '#2E2630',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    transition: 'height 0.5s ease-in-out',
  },
  referalCardInner: {
    width: '80%',
    textAlign: 'center',
    color: theme.palette.background.paper,
    padding: theme.spacing(1, 4, 0, 4),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 4),
    },
  },
  referralCardTypography: {
    margin: 0,
    padding: theme.spacing(0, 0, 2, 0),
  },
  referralCardCloseButton: {
    position: 'absolute',
    right: 16,
    top: 4,
    color: theme.palette.background.paper,
    '&:hover': {
      color: fade(theme.palette.background.paper, 1 - theme.palette.action.activatedOpacity),
    },
  },

  hidden: {
    display: 'none',
    padding: 0,
    margin: 0,
  },
  itemsSpacing: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3),
    height: '100%',
  },
  noAnimateContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
}))

function Marquee(props) {
  const {
    text,
    animate = true,
    textCase,
    closeable = false,
    backColor,
    textColor,
    mheight = '35',
    txtAlign,
    verticalAlign,
    renderIndex,
    items,
    itemsSpacing,
  } = props

  const [hideMarquee, setHideMarquee] = React.useState('flex')
  const [heightRefCard, setHeightRefCard] = React.useState(0)
  const [referralCard, setReferralCard] = React.useState(null)

  const classes = useStyles(props)
  const router = useRouter()
  const t = useTranslations()
  const apiClient = getApiClient()
  const { currencyData, market } = useGlobal()

  const contentRef = React.useRef(null)
  const animateRef = React.useRef(null)
  const nonAnimateRef = React.useRef(null)

  const rafRef = React.useRef(null)
  const xRef = React.useRef(0)
  const widthRef = React.useRef(0)
  const wrapperRef = React.useRef(null)

  const syncWidth = React.useCallback(() => {
    widthRef.current = wrapperRef.current?.clientWidth
  }, [])

  const start = React.useCallback(() => {
    if (!animate) return
    if (!wrapperRef.current) return

    xRef.current = xRef.current > widthRef.current / 2 ? 0 : xRef.current + 0.6
    wrapperRef.current.style.transform = `translate3D(-${xRef.current}px, 0, 0)`

    rafRef.current = requestAnimationFrame(start)
  }, [animate])

  const stop = React.useCallback(() => {
    cancelAnimationFrame(rafRef.current)
  }, [])

  React.useEffect(() => {
    const handleResize = debounce(() => {
      syncWidth()
    })

    window.addEventListener('resize', handleResize, { passive: true })
    return () => {
      handleResize.clear()
      window.removeEventListener('resize', handleResize)
    }
  }, [syncWidth])

  React.useEffect(() => {
    syncWidth()
    return stop
  }, [syncWidth, stop])

  React.useEffect(() => {
    const referralCardCode = router.query['referral-card'] || ''

    if (referralCardCode !== '') {
      setHideMarquee('none')
      const getReferralCard = async () => {
        const data = await apiClient.getReferralCards(referralCardCode)

        if (data && data?.marketId === market?.id) {
          Cookies.set('REFERRAL_CARD', referralCardCode, {
            httpOnly: false,
            secure: process.env.NODE_ENV === 'production',
            domain: publicRuntimeConfig.COOKIE_DOMAIN,
            sameSite: publicRuntimeConfig.COOKIE_SAMESITE,
          })

          setReferralCard(data || null)
        } else setHideMarquee('flex')
      }

      getReferralCard()
    }
  }, [apiClient, market?.id, router.query])

  React.useEffect(() => {
    let marqueeElements = null
    if (document) marqueeElements = document.querySelectorAll('.marquee')

    if (referralCard) {
      setTimeout(() => {
        setHideMarquee('flex')

        if (contentRef.current?.id === marqueeElements?.[0]?.id) {
          contentRef.current?.classList.remove(classes.hidden)
          animateRef.current?.classList.add(classes.hidden)
          nonAnimateRef.current?.classList.add(classes.hidden)

          setHeightRefCard(contentRef.current?.scrollHeight + 16)
        } else {
          contentRef.current?.classList.add(classes.hidden)
          animateRef.current?.classList.remove(classes.hidden)
          nonAnimateRef.current?.classList.remove(classes.hidden)
        }
      }, [500])
    }
  }, [classes.hidden, referralCard])

  return (
    <Section
      className={classes.root}
      style={{
        display: hideMarquee,
        backgroundColor: backColor,
        height: referralCard ? 'auto' : mheight && Number(mheight),
        alignItems: verticalAlign === '' ? 'center' : verticalAlign,
        borderTop: referralCard ? 0 : `1px solid #2E2630`,
        justifyContent: txtAlign === '' ? 'center' : txtAlign,
      }}
    >
      <div
        className={clsx('marquee', classes.referralCardContainer, {
          [classes.hidden]: !referralCard,
        })}
        style={{ height: heightRefCard }}
        ref={contentRef}
        id={`marquee-${renderIndex}`}
      >
        <IconButton
          className={classes.referralCardCloseButton}
          onClick={() => setHeightRefCard(0)}
          edge="end"
        >
          <CloseIcon />
        </IconButton>
        <div className={classes.referalCardInner}>
          <Typography variant="subtitle2">
            {t('Web.ReferralCard.Text').replace(
              '{amount}',
              `${referralCard?.amount || 0} ${
                currencyData?.isoCodeAlpha || currencyData?.sign || ''
              }`,
            )}
          </Typography>
        </div>
      </div>

      {animate ? (
        <>
          <InView className={clsx(classes.bounds)} onEnter={start} onExit={stop} ref={animateRef} />
          <div className={classes.wrapper} style={{ gap: itemsSpacing || '0px' }} ref={wrapperRef}>
            <span className={classes.text} style={{ textTransform: textCase, color: textColor }}>
              {txtAlign === 'center' ? <center>{render(text)}</center> : render(text)}
            </span>
            <div className={classes.itemsSpacing} style={{ gap: itemsSpacing }}>
              {items?.map((item, index) => (
                <MarqueeItem key={index} {...item} textColor={textColor} />
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className={clsx(classes.noAnimateContainer)}>
          <div className={classes.itemsSpacing} style={{ gap: itemsSpacing }}>
            {items?.map((item, index) => (
              <MarqueeItem key={index} {...item} textColor={textColor} />
            ))}
          </div>
          <span
            className={clsx(classes.noAnimateText)}
            style={{
              textAlign: txtAlign,
              textTransform: textCase,
              color: textColor,
              display: referralCard ? 'none' : 'block',
            }}
            ref={nonAnimateRef}
          >
            {render(text)}
          </span>
        </div>
      )}

      {closeable && !referralCard && (
        <IconButton
          className={classes.closeButton}
          onClick={() => setHideMarquee('none')}
          edge="end"
        >
          <CloseIcon />
        </IconButton>
      )}
    </Section>
  )
}

Marquee.propTypes = {
  text: PropTypes.object.isRequired,
  animate: PropTypes.bool,
  textCase: PropTypes.oneOf([undefined, '', 'uppercase', 'lowercase']),
  closeable: PropTypes.bool,
  backColor: PropTypes.string,
  textColor: PropTypes.string,
  mheight: PropTypes.string,
  txtAlign: PropTypes.oneOf(['', 'center', 'left', 'right']),
  verticalAlign: PropTypes.oneOf(['', 'center', 'left', 'right']),
  renderIndex: PropTypes.number,
  items: PropTypes.array,
  itemsSpacing: PropTypes.string,
}

export default Marquee
