import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Media from '@oakwood/oui/Media'
import MediaReveal from '@oakwood/oui/MediaReveal'
import { render } from 'storyblok-rich-text-react-renderer'
import { media } from 'utils/transforms'

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    height: '100%',
  },
  mediaContainer: {
    width: 28,
    height: 28,
    overflow: 'hidden',
  },
  media: {
    width: '100%',
    height: '100%',
    '& > img': {
      objectFit: 'contain',
    },
  },
  text: {
    whiteSpace: 'nowrap',
  },
}))

const MarqueeItem = (props) => {
  const { icon, text, textColor } = props

  const classes = useStyles()
  const imageUrl = media(icon)

  return (
    <div className={clsx(classes.root)}>
      {icon?.filename !== '' && (
        <MediaReveal className={classes.mediaContainer}>
          <Media className={classes.media} {...imageUrl} />
        </MediaReveal>
      )}

      <span className={classes.text} style={{ color: textColor }}>
        {render(text)}
      </span>
    </div>
  )
}

MarqueeItem.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  textColor: PropTypes.string,
}

export default MarqueeItem
